import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useGetUserProfileQuery } from "../store/User/userApi";
import {
  isAuth,
  logout,
  selectAllCurrentUserData,
  selectCurrentUserData,
} from "../store/auth";
import { storage } from "../utils/helper";


const withAuth = (WrappedComponent, redirectPath = '/login') => {
  const AuthComponent = (props) => {
    const dispatch = useDispatch();
    const { data: userProfile, isSuccess, isError } = useGetUserProfileQuery();
    const user = userProfile?.data;
    const isAuthState = useSelector(isAuth);
    const getUser = useSelector(selectCurrentUserData);
    const getUserAll = useSelector(selectAllCurrentUserData);
    const isTokenExpired = (token) => {
      if (!token) return true;

      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp < currentTime;
      } catch (error) {
        console.error('Invalid token format:', error);
        return true;
      }
    };

      useEffect(() => {
        if(isSuccess){
          storage.localStorage.set('userProfile_pharmaServe',user)
        }
      
      }, [isSuccess])
      
    // console.log(userProfile?.data?.userRole==="company_admin",'isAuthState')
    console.log(getUser)
    useEffect(() => {
      if (!getUser?.id || isTokenExpired(storage['localStorage'].get('user_details_profile')?.token)) {
        dispatch(logout());
      }
    }, [getUser?.id]);

    if (!storage['localStorage'].get('user_details_profile')?.token) {
      return <Navigate to={redirectPath} />;
    }
   

    if (true) {
      return <WrappedComponent user={user} {...props} />;
    }

    return true;
  };

  return AuthComponent;
};


export default withAuth;
