import React from "react";
import InputComp from "../../Components/Ui/InputComp";
import ButtonComp from "../../Components/Ui/Button";
import useFormValidator from "use-form-input-validator";
import { useForgetPasswordMutation } from "../../store/auth/authApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { storage } from "../../utils/helper";


export const ForgotPassword = () => {
  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    email: { checks: "required|email", value: "" },
  });
  const [forgotPassword, { isLoading, isSuccess, data }] = useForgetPasswordMutation();
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isAllFieldsValid()) {
      const forgotPassDetails = await forgotPassword(values);
      const { data, error } = forgotPassDetails;
      
      if (error) {
        console.log(error.data.message)
        return toast.error(error?.data?.message);
      }
      storage["localStorage"].set("verified_mail", values.email)
      toast.success(data?.message)
      navigate("/verify-code")
      
    }
  };
  return (
    <div className=" ">
      <div className="flex justify-start items-center mb-32">
        <div className="px-[50px] lg:px-[80px] lg:pl-[72px] flex-1">
          <div className="mb-5 text-center">
            <h3 className="text-[32px] font-semibold mb-[0px]">
              Forgot Password
            </h3>
            <p className="text-[#333333]">
            A link to set up a new password will be sent to your email.
            </p>
          </div>
          <form
            className="w-full lg:pr-[119px] row gy-4 "
            autoComplete="off"
          >
            <InputComp
              label={"Email"}
              name="email"
              onChange={updateField}
              value={values.email}
              error={errors.email}
              placeholder={"Enter your email address"}
              containerClassName={
                "border-[#918EF4] border-x-0 border-t-0 rounded-0  !border-b-[1px]"
              }
            />
            

            {/* <TextInputComp label={""} /> */}
            <ButtonComp
              onClick={handleSubmit}
              loader={isLoading}
              text={"Proceed"}
              btnClassName={
                "bg-[#8652CE] shadow-lg text-white py-[10px] rounded-full w-full text-center font-medium "
              }
            />
            
            <div className="text-[#282728] text-center flex justify-center items-center gap-2 text-[14px]">
              Get in touch with us via{" "}
              <span
                onClick={() => redirectToWhatsApp()}
                className="text-[#4F008A] hover:underline cursor-pointer flex items-center gap-1"
              >
                {" "}
                <span className="text-[#25D366] cursor-pointer">Whatsapp</span>
                <img src="/icon/whatsapp.svg" />
              </span>{" "}
            </div>
          </form>
        </div>
        {/*  */}
      </div>
      <div className="flex justify-between items-center w-full container text-[14px] text-[#1A002E]">
        <div>© PharmaServ 2023</div>
        <div>Privacy Terms and Condition</div>
      </div>
    </div>
  );
};
