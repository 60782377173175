import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";

export const AnalyticsApi = createApi({
  reducerPath: "AnalyticsApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    getDepartmentAnalytics: builder.query({
      query: ({ companyId, time }) => ({
        url: `/analytics/department/analytics/${companyId}?time=${time}`,
        method: "GET",
      }),
    }),
    getInstitutionAnalytics: builder.query({
      query: ({ companyId, time }) => ({
        url: `/analytics/institution/analytics/${companyId}?time=${time}`,
        method: "GET",
      }),
    }),
    getMedrepAnalytics: builder.query({
      query: ({ companyId, time }) => ({
        url: `/analytics/medrep/analytics/${companyId}?time=${time}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetDepartmentAnalyticsQuery,
  useLazyGetInstitutionAnalyticsQuery,
  useLazyGetMedrepAnalyticsQuery,
} = AnalyticsApi;
