import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { getAuthToken, getToken } from "../utils/helper";
import { userApi } from "./User/userApi";
import { authApi } from "./auth/authApi";

import { DashBoardApi } from "./DashBoard/DashBoardApi";

import { ProductApi } from "./Product/ProductApi";
import { OrderApi } from "./Order/OrderApi";
import { CustomerApi } from "./Customer/CustomerApi";
import { SchedulesApi } from "./Schedules/SchedulesApi";
import { EventApi } from "./Event/EventApi";
import { MediaApi } from "./Media/MediaApi";
import { CallReportApi } from "./CallReport/CallReportApi";
import { MedRepApi } from "./MedRep/MedRepApi";
import { PlansApi } from "./Plans/PlansApi";
import { AnalyticsApi } from "./Analytics/AnalyticsApi";

const result = getToken();

export const store = configureStore({
  // preloadedState: result ? { auth: result } : undefined,
  reducer: {
    auth: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
   
    [DashBoardApi.reducerPath]: DashBoardApi.reducer,
    [ProductApi.reducerPath]: ProductApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [CustomerApi.reducerPath]: CustomerApi.reducer,
    //
    [SchedulesApi.reducerPath]: SchedulesApi.reducer,
    [EventApi.reducerPath]: EventApi.reducer,
    [MediaApi.reducerPath]: MediaApi.reducer,
    [CallReportApi.reducerPath]: CallReportApi.reducer,
    [MedRepApi.reducerPath]: MedRepApi.reducer,
    [PlansApi.reducerPath]: PlansApi.reducer,
    [AnalyticsApi.reducerPath]: AnalyticsApi.reducer,

    //PlansApi
 
    // [managerApi.reducerPath]: managerApi.reducer,
    // [adminApi.reducerPath]: adminApi.reducer,
    // managers: mangerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userApi.middleware,
 
      DashBoardApi.middleware,
      ProductApi.middleware,
      OrderApi.middleware,
      CustomerApi.middleware,
      //
      SchedulesApi.middleware,
      EventApi.middleware,
      MediaApi.middleware,
      CallReportApi.middleware,
      MedRepApi.middleware,
      PlansApi.middleware,
      AnalyticsApi.middleware,
    
    ),
});

export default store
// setupListeners(store.dispatch);
