import React, { useState } from "react";
import InputComp from "../../Components/Ui/InputComp";
import ButtonComp from "../../Components/Ui/Button";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import useFormValidator from "use-form-input-validator";
import { useResetPasswordMutation } from "../../store/auth/authApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { storage } from "../../utils/helper";
import axios from "axios";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    password: { checks: "required", value: "" },
  });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isAllFieldsValid()) {
      setIsLoading(true);
      const token = storage.localStorage.get("verification")?.token;
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}auth/reset-password`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Bearer token
            },
          }
        );
        if (response?.data?.status) {
          setIsLoading(false);
          toast.success(response?.data?.message);
          navigate("/login");
          storage.localStorage.remove("verification");
        }
      } catch (error) {
        setIsLoading(false);
        return toast.error("Internal Server Error");
      }
    }
  };
  return (
    <div className=" ">
      <div className="flex justify-start items-center mb-32">
        <div className="px-[50px] lg:px-[80px] lg:pl-[72px] flex-1">
          <div className="mb-5 text-center">
            <h3 className="text-[32px] font-semibold mb-[0px]">
              Reset Password
            </h3>
            <p className="text-[#333333]">Kindly input your new password</p>
          </div>
          <form className="w-full lg:pr-[119px] row gy-4 " autoComplete="off">
            <div className="mb-4">
              <InputComp
                label={"Password"}
                name="password"
                onChange={updateField}
                type={showPassword ? "password" : "text"}
                value={values.password}
                error={errors.password}
                containerClassName={
                  "border-[#918EF4] border-x-0 border-t-0 rounded-0  !border-b-[1px]"
                }
                placeholder={"Enter your password"}
                prefixIcon={
                  <div
                    className="mx-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible size={25} />
                    ) : (
                      <AiOutlineEye size={25} />
                    )}
                  </div>
                }
              />
              <div className="flex justify-between mt-3">
                <div className="flex gap-1 items-center text-[#757575] text-[13px]">
                  <input type="checkbox" /> Remember me
                </div>
                <div
                  onClick={() => navigate("/forgot-password")}
                  className="text-[#4F008A] text-end text-[14px] mt-[4px] cursor-pointer"
                >
                  Forgot Password?
                </div>
              </div>
            </div>

            <ButtonComp
              onClick={handleSubmit}
              loader={isLoading}
              text={"Proceed"}
              btnClassName={
                "bg-[#8652CE] shadow-lg text-white py-[10px] rounded-full w-full text-center font-medium "
              }
            />

            <div className="text-[#282728] text-center flex justify-center items-center gap-2 text-[14px]">
              Get in touch with us via{" "}
              <span
                onClick={() => redirectToWhatsApp()}
                className="text-[#4F008A] hover:underline cursor-pointer flex items-center gap-1"
              >
                {" "}
                <span className="text-[#25D366] cursor-pointer">Whatsapp</span>
                <img src="/icon/whatsapp.svg" />
              </span>{" "}
            </div>
          </form>
        </div>
        {/*  */}
      </div>
      <div className="flex justify-between items-center w-full container text-[14px] text-[#1A002E]">
        <div>© PharmaServ 2023</div>
        <div>Privacy Terms and Condition</div>
      </div>
    </div>
  );
};

export default ResetPassword;
