import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useFormValidator from "use-form-input-validator";
import InputComp from "../Components/Ui/InputComp";
import { useUserLoginMutation } from "../store/auth/authApi";
import ButtonComp from "../Components/Ui/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserData, setUserData } from "../store/auth";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const AuthLayout = () => {
  const user = useSelector(selectCurrentUserData);
  console.log(user)
  if (user?.id) {
    return <Navigate to="/overview" replace />;
  }
  return (
    <div className="flex flex-wrap xl:flex-nowrap ">
      <div className="w-full xl:w-[50%]  relative d-none d-xl-block">
        <div className=" bg-[#4F008A]  h-[100vh] w-full bg-[url('/public/icon/background1.png')] bg-contain text-center   top-0 bottom-0 flex  items-center justify-center">
          <div className="relative text-center flex flex-col">
            <div
              className="pt-[30px] flex justify-center align-bottom"
              onClick={() => navigate("/privacy")}
            >
              <img
                src="/login.png"
                alt=""
                className="  w-[60%] object-contain mb-[51px]"
              />
            </div>
            <div className="text-[25px] text-white font-medium">
              Supercharge your medical detailing <br /> effort across different
              Healthcare
              <br /> facilities
            </div>
          </div>
        </div>
      </div>
      <div className="w-full xl:w-[50%] h-[100vh]  scrollable flex justify-center flex-col items-center">
        <Outlet />
        {/* <div className="flex justify-between items-center w-full container mb-[50px] text-[14px] text-[#1A002E]">
          <div>© PharmaServ 2023</div>
          <div>Privacy Terms and Condition</div>
        </div> */}
      </div>
    </div>
  );
};

export default AuthLayout;
