import React, { useEffect, useState } from "react";
import BarChartVertical from "../../../Components/Common/Charts/BarChartVertical";
import { BarChart } from "recharts";
import BarChartComp from "../../../Components/Common/Charts/BarChart";
import { LanguageName, modifyData, storage } from "../../../utils/helper";
import { Link } from "react-router-dom";
import TestModal from "./TestModal";
import ModalComp from "../../../Components/Ui/ModalComp";
import {
  useLazyGetDepartmentAnalyticsQuery,
  useLazyGetInstitutionAnalyticsQuery,
  useLazyGetMedrepAnalyticsQuery,
} from "../../../store/Analytics/AnalyticsApi";
import NoData from "../../../Components/Common/NoData";

export default function Analysis({ setPage }) {
  const [departmentSession, setDepartmentSession] = useState({
    name: "Daily",
    value: "daily",
  });
  const [institutionSession, setInstitutionSession] = useState({
    name: "Daily",
    value: "daily",
  });
  const [medrepSession, setMedrepSession] = useState({
    name: "Daily",
    value: "daily",
  });
  const session = [
    { name: "Daily", value: "daily" },
    { name: "Monthly", value: "monthly" },
    { name: "Annually", value: "annually" },
  ];
  const [testing, setTesting] = useState(false);
  const companyId = storage.localStorage.get("userProfile_pharmaServe")?.company
    ?._id;
  const [triggerDepartment, { data: depData, isLoading: depLoader }] =
    useLazyGetDepartmentAnalyticsQuery();
  const [triggerInstitution, { data: instData, isLoading: instisLoading }] =
    useLazyGetInstitutionAnalyticsQuery();
  const [triggerMedrep, { data: medrepData, isLoading: medisLoading }] =
    useLazyGetMedrepAnalyticsQuery();
  useEffect(() => {
    triggerDepartment({ time: departmentSession.value, companyId });
    triggerInstitution({ time: institutionSession.value, companyId });
    triggerMedrep({ time: medrepSession.value, companyId });
  }, []);
  return (
    <div>
      <div className="p-[40px]  border-[#4F008A1A] border-[1px] rounded-[10px]">
        <div className="flex justify-between items-center">
          <div>
            <div className="text-[#272B3F] text-[14px] mb-1">
              {LanguageName("TV")}
            </div>
            <div className="text-[24px] text-[#272B3F] font-medium">
              {LanguageName("Department")}
            </div>
          </div>

          <div className="bg-[#F6F6F6] py-[7px] px-[13px] rounded-lg flex items-center gap-[5px]">
            {session?.map((item, i) => (
              <div
                className={`px-[12px] py-[9px] rounded-sm text-[12px] ${
                  item.value === departmentSession.value
                    ? "bg-[#000000] text-white"
                    : ""
                } cursor-pointer`}
                onClick={() => {
                  setDepartmentSession({ name: item.name, value: item.value });
                  triggerDepartment({ time: item.value, companyId });
                }}
                key={i}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>

        {depData?.data?.departmentAnalytics.length === 0 ? (
          <NoData />
        ) : (
          <BarChartVertical data={depData?.data?.departmentAnalytics} />
        )}
      </div>

      <div className="row mt-5">
        <div className="col-7">
          <div className="p-[40px]  border-[#4F008A1A] border-[1px] rounded-[10px]">
            <div className="flex justify-between items-center">
              <div>
                <div className="text-[#272B3F] text-[12px] mb-1">
                  % {LanguageName("Coverage")}
                </div>
                <div className="text-[18px] text-[#272B3F] font-medium">
                  {LanguageName("IT")}
                </div>
              </div>

              <div className="bg-[#F6F6F6] py-[7px] px-[13px] rounded-lg flex items-center gap-[5px]">
                {session?.map((item, i) => (
                  <div
                    className={`px-[12px] py-[9px] rounded-sm text-[12px] ${
                      item.value === institutionSession.value
                        ? "bg-[#000000] text-white"
                        : ""
                    } cursor-pointer`}
                    onClick={() => {
                      setInstitutionSession({
                        name: item.name,
                        value: item.value,
                      });
                      triggerInstitution({ time: item.value, companyId });
                    }}
                    key={i}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
            {instData?.data?.institutionAnalytics.length === 0 ? (
              <NoData />
            ) : (
              <BarChartComp
                data={instData?.data?.institutionAnalytics}
                title="institution"
              />
            )}
          </div>
        </div>
        <div className="col-5">
          <div className="p-[40px]  border-[#4F008A1A] border-[1px] rounded-[10px]">
            <div className="flex justify-between items-center">
              <div>
                <div className="text-[#272B3F] text-[12px] mb-1">
                  % {LanguageName("CBON")}
                </div>
                <div className="text-[18px] text-[#272B3F] font-medium">
                  {LanguageName("MR")}
                </div>
              </div>

              <div className="bg-[#F6F6F6] py-[7px] px-[13px] rounded-lg flex items-center gap-[5px]">
                {session?.map((item, i) => (
                  <div
                    className={`px-[12px] py-[9px] rounded-sm text-[12px] ${
                      item.value === medrepSession.value
                        ? "bg-[#000000] text-white"
                        : ""
                    } cursor-pointer`}
                    onClick={() => {
                      setMedrepSession({ name: item.name, value: item.value });
                      triggerMedrep({ time: item.value, companyId });
                    }}
                    key={i}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
            {medrepData?.data?.medRepAnalytics.length === 0 ? (
              <NoData />
            ) : (
              <BarChartComp
                height={150}
                data={medrepData?.data?.medRepAnalytics}
              />
            )}

            <div>
              <div className="flex mb-4 items-center justify-between font-[500] text-[#292D32] text-[18px]">
                <p>Med Reps</p>
                <p>% Coverage</p>
              </div>
              {medrepData?.data?.medRepAnalytics.map((item) => (
                <div className="flex mb-2 items-center justify-between font-[400] text-[#121212] text-[16px]">
                  <p>{`${item.firstname} ${item.lastName}`}</p>
                  <div className="flex items-center text-[14px]">
                    <span>{item.totalPlans}</span>
                    {item.totalPlans < 10 ? (
                      <svg
                        width="12"
                        height="11"
                        viewBox="0 0 12 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.31569 10.5382C5.97153 11.1539 5.11113 11.1539 4.76697 10.5382L0.120782 2.22568C-0.22338 1.60993 0.206823 0.840258 0.895146 0.840258L10.1875 0.840258C10.8758 0.840258 11.306 1.60993 10.9619 2.22568L6.31569 10.5382Z"
                          fill="#EB5757"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="12"
                        height="11"
                        viewBox="0 0 12 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.76731 0.461806C5.11147 -0.153936 5.97188 -0.153935 6.31604 0.461807L10.9622 8.77432C11.3064 9.39007 10.8762 10.1597 10.1879 10.1597H0.895492C0.207168 10.1597 -0.223033 9.39007 0.121129 8.77432L4.76731 0.461806Z"
                          fill="#1FE08F"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              ))}
              <div className="flex mt-4 items-center justify-between font-[400] text-[#888888] text-[16px]">
                <p>More</p>
                <Link
                  to="/account/med_rep"
                  className="flex items-center text-[14px]"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4301 18.8201C14.2401 18.8201 14.0501 18.7501 13.9001 18.6001C13.6101 18.3101 13.6101 17.8301 13.9001 17.5401L19.4401 12.0001L13.9001 6.46012C13.6101 6.17012 13.6101 5.69012 13.9001 5.40012C14.1901 5.11012 14.6701 5.11012 14.9601 5.40012L21.0301 11.4701C21.3201 11.7601 21.3201 12.2401 21.0301 12.5301L14.9601 18.6001C14.8101 18.7501 14.6201 18.8201 14.4301 18.8201Z"
                      fill="#888888"
                    />
                    <path
                      d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z"
                      fill="#888888"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalComp
        handleClose={() => {
          setPage("overview");
          setTesting(false);
        }}
        show={testing}
        position={"fixed top-2 right-[10%]"}
        bodyComponents={<TestModal />}
      />
    </div>
  );
}
